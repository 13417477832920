<template>
  <div
    class="tab-pane fade show active"
    id="pills-home"
    role="tabpanel"
    aria-labelledby="pills-home-tab"
  >
    <!--one tab -->
    <div class="card p-0 mt-0" style="width: 100%; border: 0px">
      <div class="row px-4 mt-0">
        <div class="col p-5 p-0 mx-3">
          <h4 class="mb-3 text-primary">No. of Downlink Messages</h4>
          <select
            id="dropdown1"
            v-model="inputs.dropdown1"
            class="form-select mb-3"
            aria-label="Select example"
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>

          <h4 class="mb-3 text-primary">TX Power Level</h4>
          <select
            id="dropdown2"
            v-model="inputs.dropdown2"
            class="form-select mb-3"
            aria-label="Select example"
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
          </select>

          <h4 class="mb-3 text-primary">Uplink Function Type</h4>
          <select
            id="dropdown3"
            v-model="inputs.dropdown3"
            class="form-select mb-3"
            aria-label="Select example"
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>

          <h4 class="mb-3 text-primary">Test Mode Sleep time</h4>
          <select
            id="dropdown5"
            class="form-select mb-3"
            v-model="inputs.dropdown5"
            aria-label="Select example"
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
          </select>

          <h4 class="mb-3 text-primary">Downlink Frequency</h4>
          <select
            id="dropdown6"
            class="form-select mb-3"
            v-model="inputs.dropdown6"
            aria-label="Select example"
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>

        <div class="col mx-3 mt-8">
          <ul class="list-group form-check-sm h5 mt-10">
            <li class="list-group-item p-3 text-primary border-0">
              <input
                class="form-check-input me-4 form-check-sm border border-primary"
                type="checkbox"
                id="checkbox1"
                aria-label="..."
              />
              CR (Configuration Required)
            </li>
            <li class="list-group-item p-3 text-primary border-0">
              <input
                class="form-check-input me-4 border border-primary"
                type="checkbox"
                id="checkbox2"
                aria-label="..."
              />
              AR (Access Required)
            </li>
            <li class="list-group-item p-3 text-primary border-0">
              <input
                class="form-check-input me-4 border border-primary"
                type="checkbox"
                id="checkbox3"
                aria-label="..."
              />
              FGR (Fuel Gauge Reset)
            </li>
            <li class="list-group-item p-3 text-primary border-0">
              <input
                class="form-check-input me-4 border border-primary"
                type="checkbox"
                id="checkbox4"
                aria-label="..."
              />
              DSR (Device Soft Reset)
            </li>
            <li class="list-group-item p-3 text-primary border-0">
              <input
                class="form-check-input me-4 border border-primary"
                type="checkbox"
                id="checkbox5"
                aria-label="..."
              />
              BC (Battery Changed)
            </li>
            <li class="list-group-item p-3 text-primary border-0">
              <input
                class="form-check-input me-4 border border-primary"
                type="checkbox"
                id="checkbox6"
                aria-label="..."
              />
              TM (Test Mode)
            </li>
            <li class="list-group-item p-3 text-primary border-0">
              <input
                class="form-check-input me-4 border border-primary"
                type="checkbox"
                id="checkbox7"
                aria-label="..."
              />
              DS (Downlink Security)
            </li>
          </ul>

          <div class="form-group row mt-5 mx-5">
            <label
              for="btn"
              @click="button2_click($event)"
              id="button2"
              class="col-sm-4 col-form-label btn btn-primary text-white"
              >Get Unix Time</label
            >
            <div class="col-sm-6">
              <input
                readonly
                type="text"
                class="form-control border border-primary"
                id="input2"
                v-model="inputs.input2"
                placeholder="Unix time"
              />
            </div>
          </div>
        </div>
        <!-- <div class="w-100"></div> -->
        <!-- <div
          class="
            form-check form-check-custom form-check-solid form-check-sm
            mb-5
          "
        ></div> -->
        <!-- <div class="col">
          <a href="#" class="btn btn-primary">Compose DownLink 3 </a>
        </div> -->
      </div>
    </div>
    <div class="row w-100">
      <div class="form-group row mt-3 mb-5 w-50 mx-auto">
        <div class="col">
          <label
            for="btn"
            id="button1"
            @click="button1_click($event)"
            class="btn btn-primary text-white"
            >Compose Downlink 3</label
          >
        </div>
        <div class="col">
          <input
            type="text"
            readonly
            id="input1"
            class="form-control border border-primary"
            v-model="inputs.input1"
          />
        </div>
      </div>
    </div>
    <!--one tab end -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import jQuery from "jquery";
const $ = jQuery;
// window.$ = $;

export default defineComponent({
  name: "downlink3",
  components: {},
  setup() {
    const inputs = ref({
      input2: "",
      hex: "",
      input1: "",
      dropdown1: null,
      dropdown2: null,
      dropdown3: null,
      dropdown4: null,
      dropdown5: null,
      dropdown6: null,
    });

    onMounted(() => {
      $("#checkbox1").prop("checked", false);
      $("#checkbox2").prop("checked", false);
      $("#checkbox3").prop("checked", false);
      $("#checkbox4").prop("checked", false);
      $("#checkbox5").prop("checked", false);
      $("#checkbox6").prop("checked", false);
      $("#checkbox7").prop("checked", false);
    });

    const button2_click = (event) => {
      var ts = Math.round(new Date().getTime() / 1000);
      inputs.value.input2 = ts;
    };

    const button1_click = (event) => {
      let myDownlink3 = new Uint8Array([0, 0, 0, 0, 0, 0, 0, 0]);

      insert_data(myDownlink3, 0, 3, 3);

      if (inputs.value.dropdown1.length === 0) {
        inputs.value.input1 = "Please Select No. of Downlinks";
      } else {
        insert_data(myDownlink3, 3, 2, inputs.value.dropdown1);

        if (inputs.value.dropdown2.length === 0) {
          inputs.value.input1 = "Please Select TX Power";
        } else {
          insert_data(myDownlink3, 5, 4, inputs.value.dropdown2);

          if (inputs.value.dropdown3.length === 0) {
            inputs.value.input1 = "Please Select Uplink Function Type";
          } else {
            insert_data(myDownlink3, 9, 2, inputs.value.dropdown3);

            if (inputs.value.dropdown5.length === 0) {
              inputs.value.input1 = "Select Test Mode Sleep time";
            } else {
              insert_data(myDownlink3, 17, 3, inputs.value.dropdown5);

              if (inputs.value.dropdown6.length === 0) {
                inputs.value.input1 = "Please Select Downlink Frequecy";
              } else {
                insert_data(myDownlink3, 26, 2, inputs.value.dropdown6);

                if (inputs.value.input2.length === 0) {
                  inputs.value.input1 = "Press Unix Time Button";
                } else {
                  insert_data(myDownlink3, 32, 32, inputs.value.input2);

                  insert_data(
                    myDownlink3,
                    11,
                    1,
                    $("#checkbox1").is(":checked")
                  );

                  insert_data(
                    myDownlink3,
                    12,
                    1,
                    $("#checkbox2").is(":checked")
                  );

                  insert_data(
                    myDownlink3,
                    13,
                    1,
                    $("#checkbox3").is(":checked")
                  );

                  insert_data(
                    myDownlink3,
                    14,
                    1,
                    $("#checkbox4").is(":checked")
                  );

                  insert_data(
                    myDownlink3,
                    15,
                    1,
                    $("#checkbox5").is(":checked")
                  );

                  insert_data(
                    myDownlink3,
                    16,
                    1,
                    $("#checkbox6").is(":checked")
                  );

                  insert_data(
                    myDownlink3,
                    20,
                    1,
                    $("#checkbox7").is(":checked")
                  );

                  let hexString3 = toHexString(myDownlink3);
                  inputs.value.input1 = hexString3;
                }
              }
            }
          }
        }
      }
    };

    function toHexString(byteArray) {
      return Array.prototype.map
        .call(byteArray, function (byte) {
          return ("0" + (byte & 0xff).toString(16)).slice(-2);
        })
        .join("")
        .toUpperCase();
    }

    function get_bit_status(value, bitNumber) {
      var ret_status = false;
      if (value & (1 << bitNumber)) {
        ret_status = true;
      }

      return ret_status;
    }

    function set_bit(value, bitNumber) {
      /* Note: Maximum 32 bit value shift is possible in JS */
      value |= 1 << bitNumber;
      return value;
    }

    function clear_bit(value, bitNumber) {
      /* Note: Maximum 32 bit value shift is possible in JS */
      value &= ~(1 << bitNumber);
      return value;
    }

    function insert_data(buffer, startBit, bitCount, value) {
      let bitStatus;
      let count;
      /* Get byte index and bit position */
      let index = parseInt(startBit / 8, 10);
      let bitPosition = parseInt(startBit % 8, 10);
      let iBuffer;

      for (count = 0; count < bitCount; count++) {
        iBuffer = 7 - index;
        bitStatus = get_bit_status(value, count);
        if (bitStatus === true) {
          /* Set bit */
          buffer[iBuffer] |= set_bit(buffer[iBuffer], bitPosition);
        } else {
          /* Clear bit */
          buffer[iBuffer] |= clear_bit(buffer[iBuffer], bitPosition);
        }

        if (bitPosition >= 7) {
          bitPosition = 0;
          index += 1;
        } else {
          bitPosition += 1;
        }
      }
    }

    return {
      button1_click,
      button2_click,
      inputs,
    };
  },
});
</script>

<style></style>
