
import axios from "axios";
import { defineComponent, onMounted, ref } from "vue";
// window.$ = $;

export default defineComponent({
  name: "uplinks",
  components: {},
  setup() {
    const model = ref({
      hex: null,
      decoded: "",
    });

    const decodeDownlinkHex = async (hex) => {
      const url = "https://api.brighterbins.com/decoder/" + hex;
      return await axios.get(hex).then((response) => response.data);
    };

    const decoceHex = () => {
      axios
        .get(`https://api.brighterbins.com/decoder/${model.value.hex}`)
        .then((response) => {
          let res = response.data;
          if (!res.exception) {
            let response = res;
            let str = `Message: ${model.value.hex}\nFrame Type: ${response.data.uplinkType.numeric}\nRaw Distance: ${response.data.rawDistance.numeric}\nTemperature: ${response.data.temperature.numeric} °C\nBattery Level: ${response.data.battery.numeric}`;
            model.value.decoded = str;
          } else {
            model.value.decoded = "Invalid data";
          }
        });
    };

    return {
      model,
      decoceHex,
    };
  },
});
