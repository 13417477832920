import axios from "axios";
import URLS from "../urls";

export const getDownLinkListing = async (payload) => {
  const url = URLS.GET_DOWNLINKS_LISTING;
  return await axios.post(url, payload).then((response) => response.data);
};

export const getDownLinkDeviceListing = async (payload) => {
  const url = URLS.GET_DOWNLINK_DEVICES;
  return await axios.post(url, payload).then((response) => response.data);
};

export const createDownLink = async (payload) => {
  const url = URLS.CREATE_DOWNLINK;
  return await axios.post(url, payload).then((response) => response.data);
};

export const deleteDownLink = async (payload) => {
  const url = URLS.DELETE_DOWNLINK;
  return await axios.post(url, payload).then((response) => response.data);
};

export const updateDownLink = async (id, payload) => {
  const url = URLS.UPDATE_DOWNLINK + id;
  return await axios.put(url, payload).then((response) => response.data);
};

export const decodeDownlinkHex = async (hex) => {
  const url = URLS.DECODE_HEX + hex;
  return await axios.get(hex).then((response) => response.data);
};
