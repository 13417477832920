
import { onMounted, defineComponent, ref } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  createDownLink,
  deleteDownLink,
  getDownLinkDeviceListing,
  getDownLinkListing,
  updateDownLink,
} from "@/store/api/downlinks";
import Multiselect from "@vueform/multiselect";
import { getCustomersList } from "@/store/api/devices";
import { hideModal } from "@/core/helpers/dom";
import moment from "moment";
import GenerateDownlink from "@/components/modals/general/GenerateDownlink.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { string } from "yup/lib/locale";

interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
    status: string;
  };
  protocol: string;
}
export default defineComponent({
  name: "Downlinks",
  components: {
    KTDatatable,
    Multiselect,
    GenerateDownlink,
  },
  setup() {
    const data = ref<any>([]);
    const list = ref<any>([]);
    const search = ref<string>("");
    const headerConfig = ref([
      {
        name: "Name",
        key: "name",
      },
      {
        name: "Device ID",
        key: "device_id",
      },
      {
        name: "Customer Name",
        key: "customer_name",
      },
      {
        name: "Downlink Hex",
        key: "downlink_hex",
      },
      {
        name: "Delivered",
        key: "send_ts",
      },
      {
        name: "status",
        key: "status",
      },
      {
        name: "Action",
        key: "action",
      },
    ]);
    const loading = ref<boolean>(true);
    const customers = ref<any[]>([]);
    const devices = ref<any>();
    const devices_options = ref<any[]>([]);
    const edit = ref<boolean>(false);
    const permissions = ref<any>("");
    const payload = ref<any>({
      id: 0,
      name: "",
      downlink_hex: "",
      devices: null,
      se_account_id: 0,
      status: 0,
      tls: 1,
      protocol: "tcp",
    });

    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
        status: "",
      },
      protocol: "udp",
    });

    const total = ref<number>(0);

    const closeHexModal = ref<null | HTMLElement>(null);

    onMounted(async () => {
      permissions.value = localStorage.getItem("permissions");
      permissions.value = JSON.parse(permissions.value);
      setCurrentPageBreadcrumbs("Downlinks", [""]);
      getCustomersList().then((response) => {
        customers.value = response;
      });
      loadData();
    });
    const loadData = async () => {
      loading.value = true;
      let res = await getDownLinkListing(pagination.value);
      data.value = res.downlinks;
      list.value = res.downlinks;
      total.value = res.total_number_of_records;
      loading.value = false;
    };

    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };

    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };

    const onCustomerChange = (event) => {
      payload.value.se_account_id = event.target.value;
      let pag = {
        customer_id: event.target.value,
      };
      getDownLinkDeviceListing(pag).then((res) => {
        devices.value = res.devices;
        devices_options.value = [];
        devices.value.forEach((element) => {
          devices_options.value.push(element.variant_id);
        });
      });
    };
    const dateTime = (value) => {
      return moment(value).format("DD-MM-YYYY h:mm:ss");
    };
    // pagination.value.customer_id
    const saveDownlink = () => {
      payload.value.devices =
        typeof payload.value.devices == "string"
          ? payload.value.devices.split()
          : payload.value.devices;

      if (edit.value == true) {
        let id = payload.value.id;
        delete payload.value.devices;
        delete payload.value.id;
        delete payload.value.se_account_id;
        payload.value.status = parseInt(payload.value.status);
        updateDownLink(id, payload.value).then((res) => {
          resetModal();
          loadData();
        });
      } else {
        delete payload.value.id;
        delete payload.value.status;
        createDownLink(payload.value).then((res) => {
          resetModal();
          loadData();
        });
      }
    };

    const resetModal = () => {
      payload.value.name = "";
      payload.value.downlink_hex = "";
      payload.value.status = 0;
      payload.value.se_account_id = "0";
      payload.value.devices = [];
      payload.value.tls = "";
      payload.value.protocol = "";
      document.getElementById("downlinkclosemodal")?.click();
    };
    const deleteDownlink = async (id, protocol) => {
      Swal.fire({
        title: "Are you sure you want to delete this Downlink?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          let payload = {
            id: id,
            protocol: pagination.value.protocol
              ? pagination.value.protocol
              : protocol,
          };
          deleteDownLink(payload).then((res) => {
            loadData();
          });
        }
      });
    };
    const onStatusChange = (event) => {
      pagination.value.page_number = 1;
      pagination.value.filters.status = event.target.value;
      loadData();
    };
    const onProtocolChange = (event) => {
      pagination.value.page_number = 1;
      pagination.value.protocol = event.target.value;
      loadData();
    };

    const onEnter = (e) => {
      console.log(e);
    };

    const editDownlink = (data) => {
      payload.value.id = data.id;
      edit.value = true;
      payload.value.name = data.name;
      payload.value.downlink_hex = data.downlink_hex;
      payload.value.status = data.status;
      payload.value.protocol = data.protocol
        ? data.protocol
        : pagination.value.protocol;
      payload.value.tls = data.tls ? data.tls : "";
    };
    const filterData = (event) => {
      for (let i = 0; i < list.value.length; i++) {
        let filters;
        if (list.value[i].status == event.target.value) {
          filters = list.value[i];
        }
        console.log(filters);
      }
    };
    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
      } else {
        pagination.value.filters.name = "";
      }
      loadData();
    };
    return {
      total,
      pagination,
      data,
      headerConfig,
      loading,
      currentChange,
      itemsPerPageChange,
      devices,
      saveDownlink,
      customers,
      onCustomerChange,
      devices_options,
      deleteDownlink,
      closeHexModal,
      editDownlink,
      edit,
      payload,
      onEnter,
      searchItems,
      search,
      onStatusChange,
      onProtocolChange,
      filterData,
      dateTime,
      permissions,
    };
  },
});
