<template>
  <!--Modal code-->

  <div class="modal fade" tabindex="-1" id="generate_link" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content roundeda">
        <div class="modal-header bg-primary">
          <span class="modal-title text-white ms-3 h5 text-uppercase"
            >Genrate DownLink</span
          >

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i
              class="bi bi-x-circle-fill light-text fs-3"
              style="color: white"
            ></i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <!--tab data start -->
          <div data-aos="fade-right">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  DOWNLINK 1
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  DOWNLINK 2
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  DOWNLINK 3
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-uplink-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-uplink"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  UP LINK
                </button>
              </li>
            </ul>
            <div class="separator my-2"></div>
            <div class="tab-content mb-20" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <!--one tab -->

                <Downlink1 />
                <!--one tab end -->
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <Downlink2 />
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <downlink-3 />
              </div>
              <div
                class="tab-pane fade"
                id="pills-uplink"
                role="tabpanel"
                aria-labelledby="pills-uplink-tab"
              >
                <!--uplink tab -->
                <Uplinks />
                <!--uplink tab end -->
              </div>
            </div>
          </div>
          <!--tab data end-->
        </div>
      </div>
    </div>
  </div>
  <!--Modal code end -->
</template>

<script>
import { defineComponent } from "vue";
import Downlink2 from "@/views/downlink/Downlink2.vue";
import Downlink3 from "@/views/downlink/Downlink3.vue";
import Uplinks from "@/views/downlink/Uplinks.vue";
import Downlink1 from "@/views/downlink/Downlink1.vue";

export default defineComponent({
  name: "generate-downlink",
  components: {
    Downlink2,
    Downlink3,
    Uplinks,
    Downlink1,
  },
});
</script>
<style scoped>
.roundeda {
  border-radius: 8px;
}
</style>
